import PropTypes from "prop-types";
import { Card } from "@mui/material";
import SoftTypography from "src/components/SoftTypography";
import SoftBox from "src/components/SoftBox";
import parse from "html-react-parser";
import Documents from "../LessonDocuments";
import { useLocation } from "react-router-dom";

const LessonAttribute = ({ title, value }) => (
  <SoftBox px={2} py={1}>
    <SoftTypography
      fontSize="small"
      fontWeight="medium"
      textTransform="uppercase"
      color="text"
      mb={1}
    >
      {title}
    </SoftTypography>
    <SoftTypography component="div" fontSize="small">
      {parse(value)}
    </SoftTypography>
  </SoftBox>
);

const LessonTime = ({ title, value }) => (
  <SoftBox width="90px" textAlign="center">
    <SoftTypography
      textTransform="uppercase"
      variant="caption"
      fontSize="extra-small"
      textAlign="center"
      fontWeight="regular"
      color="text"
    >
      {title}
    </SoftTypography>
    <SoftTypography
      textAlign="center"
      variant="h6"
      fontWeight="medium"
      color="text"
    >
      {value} {value === 1 ? "hr" : "hrs"}
    </SoftTypography>
  </SoftBox>
);

const LessonInfo = ({ lesson, children }) => {
  const { pathname } = useLocation();
  return (
    <Card
      sx={{
        backgroundColor: "primary.background",
        py: 2,
        px: 3,
        boxShadow: 0,
        overflowY: "scroll",
      }}
    >
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <SoftTypography variant="h4" mb={2}>
          Lesson Information
        </SoftTypography>
        {children}
      </SoftBox>
      <SoftBox
        borderTop={1}
        borderBottom={1}
        borderColor="secondary.focus"
        pt={1.5}
        pb={2}
        px={4}
        mb={3}
        display="flex"
        justifyContent="space-around"
        alignItems="center"
      >
        <LessonTime title="Flight Time" value={lesson.flightTime} />
        <LessonTime title="Ground Time" value={lesson.groundTime} />
        <LessonTime title="Sim Time" value={lesson.simTime} />
      </SoftBox>
      <LessonAttribute title="objective" value={lesson?.objective || "N/A"} />
      <LessonAttribute
        title="completion standards"
        value={lesson?.completionStandards || "N/A"}
      />
      <LessonAttribute
        title="additional study"
        value={lesson?.additionalStudy || "N/A"}
      />
      <SoftBox mt={2}>
        <Documents
          courseId={lesson.courseId}
          lessonId={lesson.id}
          stageId={lesson.stageId}
          add={pathname.includes("/lms/courses")}
        />
      </SoftBox>
    </Card>
  );
};

LessonInfo.propTypes = {
  lesson: PropTypes.object.isRequired,
  children: PropTypes.node,
};

LessonAttribute.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string.isRequired,
};

LessonAttribute.defaultProps = {
  title: "",
};

LessonTime.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
};

LessonTime.defaultProps = {
  title: "",
  value: 0,
};

export default LessonInfo;
