import { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useLms } from "src/features/lms/LmsProvider";

// @mui material components
import Card from "@mui/material/Card";
import { DescriptionOutlined, PersonOutline } from "@mui/icons-material";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import SoftSlider from "src/components/SoftSlider";

// *** Needed to reimplement select student
// import SoftSelect from "src/components/SoftSelect";
// import SoftSelectLabel from "src/components/SoftSelectLabel";

import LessonTable from "./components/LessonTable";

// Data
import { Skeleton } from "@mui/material";
import {
  CourseHeader,
  StageSliderCard,
  StageHeader,
  StageFooter,
  CourseFactoid,
} from "src/pages/lms/components";
import StageInfo from "./components/StageInfo";
import { getStageColor } from "src/pages/lms/utils";

function CourseOverview() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { studentId, enrollmentId, enrolledCourseId } = useParams();
  const { getSingleEnrolledCourse } = useLms(); // *** need to create func getCourseDataFromAllEnrollments for student select
  const [course, setCourse] = useState({});
  const [selectedStageIndex, setSelectedStageIndex] = useState(0);

  const getEnrolledCourse = async () => {
    const courseData = await getSingleEnrolledCourse(
      studentId,
      enrollmentId,
      enrolledCourseId
    );
    if (courseData?.stages) {
      setCourse(courseData);
      if (!selectedStageIndex) {
        setSelectedStageIndex(courseData.currentStageIndex);
      }
    }
  };

  const pageUrl = useMemo(
    () =>
      pathname.includes("training") ? "/training/classroom" : "/lms/students",
    [pathname]
  );

  const totalLessons = useMemo(() => {
    if (course.progress) {
      const total = course.progress.lessons.total;
      return total === 1 ? total + " Lesson" : total + " Lessons";
    }
  }, [course]);

  const handleBackClick = () => {
    navigate(pageUrl);
  };

  // ***: Needed to reimplement select student
  // ***: NOTE: "enrollments" refers to enrolledCourses, as this is from the old data structure
  // const [enrollments, setEnrollments] = useState([]);
  // const getCourseEnrollments = async (courseId) => {
  //   const enrollmentsData = await getCourseDataFromAllEnrollments(courseId);
  //   if (enrollmentsData.length) {
  //     setEnrollments(enrollmentsData);
  //   }
  // };
  // const handleChangeStudent = (student) => {
  //   const studentCourseData = enrollments.find(
  //     (enrollment) => enrollment.member?.value === student.value
  //   );
  //   if (studentCourseData?.member) {
  //     const params = `/${studentCourseData.member.value}/${studentCourseData.id}`;
  //     navigate(pageUrl + params);
  //     setCourse(studentCourseData);
  //   }
  // };
  // useEffect(() => {
  //   course.courseId && getCourseEnrollments(course.courseId);
  // }, [course]);

  useEffect(() => {
    getEnrolledCourse();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle={course?.title} />
      <SoftBox py={3}>
        <Card
          sx={{
            overflowX: "visible",
            overflowY: "scroll",
          }}
        >
          {course.stages?.length ? (
            <SoftBox p={3}>
              <CourseHeader course={course} onBackClick={handleBackClick}>
                {/* {course.member && enrollments.length > 1 ? (
                  <SoftSelectLabel labelText="Student" borderless>
                    <SoftSelect
                      borderless
                      onChange={handleChangeStudent}
                      defaultValue={{
                        value: course.member.value,
                        label: course.member.label,
                      }}
                      options={enrollments.map((enrollment) => ({
                        value: enrollment.member?.value,
                        label: enrollment.member?.label,
                      }))}
                    />
                  </SoftSelectLabel>
                ) : null} */}
                <CourseFactoid
                  icon={
                    <PersonOutline fontSize="extra-small" color="secondary" />
                  }
                  content={course.member.label || ""}
                />
                <CourseFactoid
                  icon={
                    <DescriptionOutlined
                      fontSize="extra-small"
                      color="secondary"
                    />
                  }
                  content={totalLessons || <Skeleton />}
                />
              </CourseHeader>
              <SoftBox>
                <SoftSlider>
                  {course?.stages.map((stage) => (
                    <StageSliderCard
                      backgroundColor={getStageColor(stage, selectedStageIndex)}
                      key={stage.id}
                      onClick={() => setSelectedStageIndex(stage.index)}
                    >
                      <StageHeader index={stage.index}>
                        <StageInfo stage={stage} />
                      </StageHeader>
                      <StageFooter
                        stage={stage}
                        progress={
                          course.progress.stages.byIndex[stage.index].progress
                        }
                      />
                    </StageSliderCard>
                  ))}
                </SoftSlider>
                <SoftBox mt={4} mb={2}>
                  <LessonTable
                    stage={course?.stages[selectedStageIndex]}
                    refreshCourseData={getEnrolledCourse}
                  />
                </SoftBox>
              </SoftBox>
            </SoftBox>
          ) : (
            <SoftBox p={3}>
              <CourseHeader course={{}} onBackClick={() => navigate(-1)}>
                <Skeleton height="100%" variant="rectangular" />
              </CourseHeader>
              <Skeleton
                variant="rectangular"
                height="140px"
                sx={{ borderRadius: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="15rem"
                sx={{ mt: 4, mb: 2 }}
              />
            </SoftBox>
          )}
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CourseOverview;
