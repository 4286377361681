import * as toastr from "toastr";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";

// Settings page components
import StripeDetails from "./components/StripeDetails";

// Data
import { doc, updateDoc } from "firebase/firestore";
import { useClubs } from "src/features/club/ClubProvider";
import {
  getClubLocationsCollection,
  getClubsCollection,
} from "src/features/club/collections";

function ClubBilling() {
  const { selectedClub, selectedClubId, selectedLocationId } = useClubs();
  const { preferences } = selectedClub;

  const updatePreference = async (key, value) => {
    preferences[key] = value;
    try {
      await updateDoc(doc(getClubsCollection(), selectedClubId), {
        preferences,
      });
      toastr.success("Your preferences have been updated.", "Success");
    } catch (e) {
      console.error(e);
      toastr.error("There was an error updating your preferences.", "Error");
    }
  };

  const updateLocationPreference = async (key, value) => {
    preferences[key] = value;
    try {
      await updateDoc(
        doc(getClubLocationsCollection(selectedClubId), selectedLocationId),
        {
          preferences,
        }
      );
      toastr.success("Your preferences have been updated.", "Success");
    } catch (e) {
      console.error(e);
      toastr.error("There was an error updating your preferences.", "Error");
    }
  };

  const toggleClubPaysCCFees = (event) => {
    updatePreference("clubPaysFees", event.target.checked);
  };
  const toggleClubPaysACHFees = (event) => {
    updatePreference("clubPaysAchFees", event.target.checked);
  };

  const toggleCanPayOnAccount = (event) => {
    updatePreference("canPayOnAccount", event.target.checked);
  };

  const toggleEnableSplitPayout = (event) => {
    updatePreference("enableSplitPayout", event.target.checked);
  };

  const toggleClubPaysFeesOnAccount = (event) => {
    updatePreference("clubPaysFeesOnAccount", event.target.checked);
  };

  const toggleDisableAddFunds = (event) => {
    updatePreference("disableAddFunds", event.target.checked);
  };

  const toggleDisableCardPayments = (event) => {
    updatePreference("disableCardPayments", event.target.checked);
  };

  const toggleDisableAircraftCheckoutFlightsPayment = (event) => {
    updatePreference("disableCheckoutPayments", event.target.checked);
  };

  const toggleAllowInstructorsToModifyFees = (event) => {
    updatePreference("instructorsModifyFees", event.target.checked);
  };

  const toggleAllowWithUnpaidInvoice = (event) => {
    updatePreference("bookWithUnpaidInvoice", event.target.checked);
  };

  const toggleAllowWithPendingPayment = (event) => {
    updatePreference("bookWithPendingPayment", event.target.checked);
  };

  const toggleRequireCardOnFile = (event) => {
    updatePreference("requireCardOnFile", event.target.checked);
  };

  const setDefaultInstructorHourlyRate = (event) => {
    updatePreference("defaultInstructorHourlyRate", event.target.value);
  };

  const setTaxRate = (event) => {
    updateLocationPreference("defaultTaxRate", event.target.value);
  };

  const toggleMaxHobbsTachHours = (event) => {
    updatePreference("limitMaxHobbsTach", event.target.checked);
  };

  const setMaxHobbsTach = (event) => {
    updatePreference("maxHobbsTach", event.target.value);
  };

  return (
    <Card
      id="club-billing"
      sx={{
        overflow: "visible",
      }}
    >
      <SoftBox p={3}>
        <SoftTypography variant="h5">Billing</SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          Update payout information and club wide billing preferences.
        </SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <StripeDetails />
          </Grid>
          <Grid item xs={12} md={6}>
            <SoftBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              height="100%"
            >
              <SoftBox pt={2} px={2}>
                <SoftTypography variant="h6" fontWeight="medium">
                  Billing Settings
                </SoftTypography>
              </SoftBox>
              <SoftBox p={2}>
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Club/School covers fees for Credit Card payments
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={preferences?.clubPaysFees}
                      onChange={toggleClubPaysCCFees}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Club/School covers fees for ACH payments
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={preferences?.clubPaysAchFees}
                      onChange={toggleClubPaysACHFees}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Club/School covers fees for account payments
                    (Cash/Check/Finance)
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      disabled={preferences?.clubPaysFees}
                      checked={preferences?.clubPaysFeesOnAccount}
                      onChange={toggleClubPaysFeesOnAccount}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Enable Pay On Account
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={preferences?.canPayOnAccount}
                      onChange={toggleCanPayOnAccount}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Disable Add Funds
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={preferences?.disableAddFunds}
                      onChange={toggleDisableAddFunds}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Disable Card Payments
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={preferences?.disableCardPayments}
                      onChange={toggleDisableCardPayments}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Disable payments for aircraft checkout flights
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={preferences?.disableCheckoutPayments}
                      onChange={toggleDisableAircraftCheckoutFlightsPayment}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Enable split payouts for Instructors
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={preferences?.enableSplitPayout}
                      onChange={toggleEnableSplitPayout}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Allow Instructors to adjust their own hourly rate
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={preferences?.instructorsModifyFees}
                      onChange={toggleAllowInstructorsToModifyFees}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Allow members to book with an unpaid invoice
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={preferences?.bookWithUnpaidInvoice}
                      onChange={toggleAllowWithUnpaidInvoice}
                    />
                  </SoftBox>
                </SoftBox>

                {!preferences?.bookWithUnpaidInvoice && (
                  <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                    lineHeight={1}
                  >
                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Allow members to book with a payment pending
                    </SoftTypography>
                    <SoftBox ml={2} mr={1}>
                      <Switch
                        checked={preferences?.bookWithPendingPayment}
                        onChange={toggleAllowWithPendingPayment}
                      />
                    </SoftBox>
                  </SoftBox>
                )}

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Require a card on file to book
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={preferences?.requireCardOnFile}
                      onChange={toggleRequireCardOnFile}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Default instructor hourly rate:
                  </SoftTypography>
                  <SoftBox ml={2} mr={1} width={100}>
                    <SoftCurrencyInput
                      currencySymbol="$"
                      placeholder="0"
                      decimalPlaces={0}
                      decimalPlacesShownOnBlur={0}
                      decimalPlacesShownOnFocus={0}
                      outputFormat="number"
                      value={preferences?.defaultInstructorHourlyRate}
                      onBlur={setDefaultInstructorHourlyRate}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Tax rate:
                  </SoftTypography>
                  <SoftBox ml={2} mr={1} width={100}>
                    <SoftCurrencyInput
                      currencySymbol="%"
                      placeholder="0"
                      decimalPlaces={2}
                      decimalPlacesShownOnBlur={2}
                      decimalPlacesShownOnFocus={2}
                      outputFormat="number"
                      value={preferences?.taxRate}
                      adornmentOnRight
                      onBlur={setTaxRate}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Limit Max Hobbs/Tach Hours
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={preferences?.limitMaxHobbsTach}
                      onChange={toggleMaxHobbsTachHours}
                    />
                  </SoftBox>
                </SoftBox>

                {preferences?.limitMaxHobbsTach && (
                  <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                    lineHeight={1}
                  >
                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Max Allowed Hobbs/Tach Hours:
                    </SoftTypography>
                    <SoftBox ml={2} mr={1} width={100}>
                      <SoftCurrencyInput
                        placeholder="0"
                        currencySymbol=""
                        decimalPlaces={0}
                        decimalPlacesShownOnBlur={0}
                        decimalPlacesShownOnFocus={0}
                        outputFormat="number"
                        value={preferences?.maxHobbsTach}
                        onBlur={setMaxHobbsTach}
                      />
                    </SoftBox>
                  </SoftBox>
                )}
              </SoftBox>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default ClubBilling;
