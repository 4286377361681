import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import SoftBox from "src/components/SoftBox";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { useClubs } from "src/features/club/ClubProvider";

import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import SoftTypography from "src/components/SoftTypography";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import colors from "src/assets/theme/base/colors";
import { AddTaskModal } from "src/modals/AddTaskModal";
import { iconButtonStyles } from "src/components/Tables/DragAndDrop/iconButtonStyles";
import {
  HeaderCell,
  HeaderCellText,
} from "src/components/Tables/DragAndDrop/HeaderCell";
import DraggableRow from "src/components/Tables/DragAndDrop/DraggableRow";
import DroppableBody from "src/components/Tables/DragAndDrop/DroppableBody";

import { DragDropContext } from "react-beautiful-dnd";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { query, orderBy } from "firebase/firestore";
import { getLessonTasksCollection } from "src/features/lms/collections";
import Swal from "sweetalert2";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";

// const { borderRadius } = borders;
const { grey } = colors;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function TaskTableContent({ tasks }) {
  const { selectedClubId } = useClubs();
  const { courseId, stageId, lessonId } = useParams();
  const { deleteData } = entityCrudUtils();
  const [selectedTask, setSelectedTask] = useState(null);

  const [_deletedTask, setDeletedTask] = useState(null);
  const [openAddTaskModal, setOpenAddTaskModal] = useState(false);

  const showAddTaskModal = () => setOpenAddTaskModal(true);
  const closeAddTaskModal = () => {
    setSelectedTask(null);
    setOpenAddTaskModal(false);
  };

  const handleEditTask = (task) => {
    setSelectedTask(task);
    showAddTaskModal();
  };

  const handleDeleteTask = (taskId) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You will not be able to recover this task!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          setDeletedTask(
            tasks?.splice(
              tasks.findIndex((val) => val.id === taskId),
              1
            )
          );
          deleteData({
            entity: getLessonTasksCollection(
              selectedClubId,
              courseId,
              stageId,
              lessonId
            ),
            pathSegmentsArr: [taskId],
          });
        }
      });
  };

  return (
    <>
      <TableHead>
        <TableRow
          sx={{
            "& > *": { backgroundColor: "white!important" },
          }}
        >
          <HeaderCell>
            <HeaderCellText>Tasks</HeaderCellText>
          </HeaderCell>
          <WithPermissions permissions={systemPermissions.EDIT_LMS_COURSES}>
            <HeaderCell alignRight>
              <IconButton
                aria-label="add"
                sx={{ ...iconButtonStyles, py: 0 }}
                onClick={showAddTaskModal}
              >
                <SoftTypography variant="button" color="primary">
                  <span style={{ paddingRight: "0.4rem" }}>+</span>
                  Add Task
                </SoftTypography>
              </IconButton>
            </HeaderCell>
          </WithPermissions>
        </TableRow>
      </TableHead>
      <TableBody component={DroppableBody(lessonId)}>
        {tasks?.length ? (
          tasks.map((task) => (
            <Row
              task={task}
              key={task.index}
              handleDeleteTask={handleDeleteTask}
              handleEditTask={handleEditTask}
            />
          ))
        ) : (
          <TableRow>
            <TableCell sx={{ color: grey[500] }}>
              <i>No Tasks Added...</i>
            </TableCell>
            <TableCell align="right">
              <SoftTypography
                fontWeight="regular"
                fontSize="small"
                sx={{ color: grey[500] }}
              >
                <i>Add your first task with the button above.</i>
              </SoftTypography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
      <Modal
        open={openAddTaskModal}
        onClose={() => closeAddTaskModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddTaskModal
            courseId={courseId}
            stageId={stageId}
            lessonId={lessonId}
            taskCount={tasks?.length ?? 0}
            taskData={selectedTask}
            handleClose={closeAddTaskModal}
          />
        </SoftBox>
      </Modal>
    </>
  );
}
TaskTableContent.propTypes = {
  tasks: PropTypes.array.isRequired,
};

function Row({ task, handleEditTask, handleDeleteTask }) {
  return (
    <>
      <TableRow component={DraggableRow(task?.id, task?.index)}>
        <TableCell>
          <span style={{ paddingRight: "2rem" }}>{task?.index + 1}</span>
          {task?.title}
          {task?.isOptional ? (
            <SoftTypography
              display="inline-block"
              fontSize="medium"
              fontWeight="light"
              color="secondary"
              sx={{ pl: 1 }}
            >
              (optional)
            </SoftTypography>
          ) : null}
        </TableCell>
        <WithPermissions permissions={systemPermissions.EDIT_LMS_COURSES}>
          <TableCell>
            <SoftBox
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <IconButton
                aria-label="delete"
                sx={iconButtonStyles}
                onClick={() => handleDeleteTask(task?.id)}
              >
                <Icon>delete</Icon>
              </IconButton>
              <IconButton
                aria-label="edit"
                sx={iconButtonStyles}
                onClick={() => handleEditTask(task)}
              >
                <Icon>edit</Icon>
              </IconButton>
            </SoftBox>
          </TableCell>
        </WithPermissions>
      </TableRow>
    </>
  );
}

Row.defaultProps = {
  handleEditTask: () => {},
  handleDeleteTask: () => {},
};
Row.propTypes = {
  task: PropTypes.object.isRequired,
  handleEditTask: PropTypes.func,
  handleDeleteTask: PropTypes.func,
};

export default function TaskTable() {
  const { selectedClubId } = useClubs();
  const { courseId, stageId, lessonId } = useParams();
  const [tasks, setTasks] = useState([]);
  const [updatingTasks, setUpdatingTasks] = useState(false);

  const { data: allTasks } = useRealtimeCollectionData(
    query(
      getLessonTasksCollection(selectedClubId, courseId, stageId, lessonId),
      orderBy("index", "asc")
    ),
    false
  );

  const onRowMove = async (newTasks, sourceIndex, destinationIndex) => {
    const { updateData } = entityCrudUtils();

    if (sourceIndex === destinationIndex) {
      return;
    } else {
      newTasks.forEach(async (task) => {
        // update task using updateData from entityCrudUtils
        await updateData(
          {
            entity: getLessonTasksCollection(
              selectedClubId,
              courseId,
              stageId,
              lessonId
            ),
            pathSegmentsArr: [task.id],
          },
          { index: task.index }
        );
      });
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination || result.destination === result.source) {
      return;
    }

    let newTasks = [...tasks];

    newTasks = reorder(newTasks, result.source.index, result.destination.index)
      .map((task, index) => ({ ...task, index }))
      .sort((a, b) => a.index - b.index);

    setTasks(newTasks);
    setUpdatingTasks(true);

    onRowMove(newTasks, result.source.index, result.destination.index);
  };

  useEffect(() => {
    if (!updatingTasks) {
      setTasks(allTasks);
    }
    setUpdatingTasks(false);
  }, [allTasks, updatingTasks]);

  return (
    <TableContainer
      sx={{
        maxHeight: "100%",
        boxShadow: "none",
        borderRadius: "0",
        marginTop: 3,
        overflowY: "scroll",
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Table
          stickyHeader
          aria-label="collapsible table"
          style={{ borderSpacing: "0 0.2rem" }}
        >
          <TaskTableContent tasks={allTasks} />
        </Table>
      </DragDropContext>
    </TableContainer>
  );
}
