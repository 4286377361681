import { Send } from "@mui/icons-material";
import { Checkbox, Grid } from "@mui/material";
import { useState } from "react";
import SoftAvatar from "src/components/SoftAvatar";
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftEditor from "src/components/SoftEditor";
import SoftTypography from "src/components/SoftTypography";
import { useUser } from "src/features/user/UserProvider";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useUserPermissions } from "src/features/user-permissions/UserPermissionsProvider";

interface INoteInputProps {
  onNewNote: (content: string, contentHTML: string, internal: boolean) => {};
  defaultInternal?: boolean;
}

export default function NoteInput({
  onNewNote,
  defaultInternal = false,
}: INoteInputProps) {
  const { user } = useUser() as any;
  const { canViewInternalInformation } = useUserPermissions();
  const isXLPage = useMediaQuery("(min-width:2000px)");
  const isSMPage = useMediaQuery("(min-width:576px)");
  const [content, setContent] = useState("");
  const [contentHTML, setContentHTML] = useState("");
  const [internal, setInternal] = useState(defaultInternal);

  const submitComment = () => {
    setContent("");
    setContentHTML("");
    setInternal(false);
    if (content?.trim()) onNewNote(content, contentHTML, internal);
  };

  const handleKeyUp = (event: any) => {
    if (event.key === "Enter") {
      submitComment();
    }
  };

  const handleContentChange = (
    contentValue: string,
    delta: any,
    source: any,
    editor: any
  ) => {
    setContentHTML(contentValue);
    setContent(editor.getText());
  };

  const handleInternalChange = () => {
    setInternal(!internal);
  };

  // The styling sucks but the SoftEditor is a creature of eldrich horror so it's good enough for now
  return (
    <Grid container spacing={0.5} width="100%">
      {isSMPage && (
        <Grid item sm={1}>
          <SoftAvatar
            src={user?.photoURL}
            shadow="xs"
            bgColor="light"
            sx={{ 
              mt: isXLPage ? 0.1 : 0.75,
              width: "100%",
              height: "auto"
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={11}>
        <SoftBox width="100%">
          <SoftEditor
            value={contentHTML}
            placeholder="Write your comment"
            onKeyUp={handleKeyUp}
            onChange={handleContentChange}
            sx={{ width: "100%" }}
          />
        </SoftBox>
      </Grid>
      <Grid item xs={12} my={1}>
        <SoftBox display="flex" justifyContent="flex-end" gap="1rem">
          {canViewInternalInformation && (
            <SoftBox display="flex" alignItems="center">
              <Checkbox
                checked={internal}
                onChange={handleInternalChange}
                inputProps={{ "aria-label": "controlled" }}
              />
              <SoftTypography fontSize="small" fontWeight="medium">
                Internal
              </SoftTypography>
            </SoftBox>
          )}
          <SoftButton
            variant="outlined"
            color="info"
            iconOnly
            onClick={submitComment}
            sx={{ width: "4rem" }}
          >
            <Send
              sx={{
                fontWeight: "bold",
              }}
            />
          </SoftButton>
        </SoftBox>
      </Grid>
    </Grid>
  );
}
