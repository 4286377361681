const pilotNames = (booking) => {
  const { pilot, instructor, pilot2 } = booking.extendedProps;

  if (pilot?.label && instructor?.label && instructor?.label !== "None") {
    return `${pilot.label} / ${instructor.label}`;
  }
  if (pilot?.label && pilot2?.label && pilot2?.label !== "None") {
    return `${pilot.label} / ${pilot2.label}`;
  }
  if (pilot?.label) {
    return pilot.label;
  }

  return "";
};

const getBookingTitle = (
  booking,
  userId,
  showNames = true,
  club,
  showAircraft
) => {
  switch (booking?.extendedProps?.type?.value) {
    case "unavailable":
      return "Unavailable";

    case "requestOnly":
      return "Request Only";

    case "maintenance":
      return "Maintenance";

    default:
      if (booking.confirmed) {
        return showAircraft
          ? `${
              club.preferences?.calendar?.hideMembersNames &&
              booking?.extendedProps?.pilot?.value !== userId &&
              !showNames
                ? "Reserved"
                : pilotNames(booking)
            } - ${booking.extendedProps.aircraft?.label}`
          : `${
              (club.preferences?.calendar?.hideMembersNames &&
              booking?.extendedProps?.pilot?.value !== userId &&
              !showNames
                ? "Reserved"
                : pilotNames(booking)) || booking.extendedProps.aircraft?.label
            }`;
      }
      return showAircraft
        ? `Pending: ${
            club.preferences?.calendar?.hideMembersNames &&
            booking?.extendedProps?.pilot?.value !== userId &&
            !showNames
              ? "Reservation"
              : pilotNames(booking)
          } - ${booking.extendedProps.aircraft?.label}`
        : `Pending: ${
            (club.preferences?.calendar?.hideMembersNames &&
            booking?.extendedProps?.pilot?.value !== userId &&
            !showNames
              ? "Reservation"
              : pilotNames(booking)) || booking.extendedProps.aircraft?.label
          }`;
  }
};

// const getBookingTitle = (booking, userId, userType, club, showAircraft) => {
//   switch (booking.extendedProps.type.value) {
//     case 'unavailable':
//       return 'Unavailable';

//     case 'requestOnly':
//       return 'Request Only';

//     case 'maintenance':
//       return 'Maintenance';

//     default:
//       if (booking.confirmed) {
//         if (booking.extendedProps.instructor.label === 'Solo') return 'Solo';

//         return showAircraft
//           ? `${getPilotName(club, booking, userId, userType, 'Reserved')} - ${
//               booking.extendedProps.aircraft?.label
//             }`
//           : `${
//               getPilotName(club, booking, userId, userType, 'Reserved') ||
//               booking.extendedProps.aircraft?.label
//             }`;
//       }

//       if (booking.extendedProps.instructor.label === 'Solo') return 'Pending: Solo';

//       return showAircraft
//         ? `Pending: ${getPilotName(club, booking, userId, userType, 'Reservation')} - ${
//             booking.extendedProps.aircraft?.label
//           }`
//         : `Pending: ${
//             getPilotName(club, booking, userId, userType, 'Reservation') ||
//             booking.extendedProps.aircraft?.label
//           }`;
//   }
// };

const getClassName = (booking, userId) => {
  if (
    ![
      "maintenance",
      "unavailable",
      "requestOnly",
      "introFlight",
      "meetingRoom",
    ].includes(booking?.extendedProps?.type?.value)
  ) {
    if (booking.completed) {
      if (
        booking.extendedProps?.pilot?.value !== userId &&
        booking.extendedProps?.pilot2?.value !== userId &&
        booking.extendedProps?.instructor?.value !== userId
      ) {
        return "completed-other";
      }
      return "completed";
    }
    if (booking.dispatched) {
      if (
        booking.extendedProps?.pilot?.value !== userId &&
        booking.extendedProps?.pilot2?.value !== userId &&
        booking.extendedProps?.instructor?.value !== userId
      ) {
        return "dispatched-other";
      }
      return "dispatched";
    }
    if (
      booking.extendedProps?.pilot?.value !== userId &&
      booking.extendedProps?.pilot2?.value !== userId &&
      booking.extendedProps?.instructor?.value !== userId &&
      booking.extendedProps?.type?.value !== "checkride"
    ) {
      return booking.extendedProps?.type?.value === "checkout"
        ? "checkout-other"
        : "reservation-other";
    }

    if (booking.extendedProps?.type?.value === "checkout") return "checkout";
    if (booking.extendedProps?.type?.value === "checkride") return "checkride";
    return "reservation";
  }

  if (booking.extendedProps?.type?.value === "pilotRefresher") {
    if (booking.completed) {
      if (
        booking.extendedProps?.pilot?.value !== userId &&
        booking.extendedProps?.instructor?.value !== userId
      ) {
        return "completed-other";
      }
      return "completed";
    }
    if (booking.dispatched) {
      if (
        booking.extendedProps?.pilot?.value !== userId &&
        booking.extendedProps?.instructor?.value !== userId
      ) {
        return "dispatched-other";
      }
      return "dispatched";
    }
    if (
      booking.extendedProps?.pilot?.value !== userId &&
      booking.extendedProps?.instructor?.value !== userId
    ) {
      return "pilotRefresher-other";
    }
    return "pilotRefresher";
  }

  if (booking.extendedProps?.type?.value === "introFlight") {
    if (booking.completed) {
      if (
        booking.extendedProps?.pilot?.value !== userId &&
        booking.extendedProps?.instructor?.value !== userId
      ) {
        return "completed-other";
      }
      return "completed";
    }
    if (booking.dispatched) {
      if (
        booking.extendedProps?.pilot?.value !== userId &&
        booking.extendedProps?.instructor?.value !== userId
      ) {
        return "dispatched-other";
      }
      return "dispatched";
    }
    if (
      booking.extendedProps?.pilot?.value !== userId &&
      booking.extendedProps?.instructor?.value !== userId
    ) {
      return "introFlight-other";
    }
    return "introFlight";
  }

  if (booking.extendedProps?.type?.value === "meetingRoom") {
    if (booking.completed) {
      if (booking.extendedProps?.member?.value !== userId) {
        return "completed-other";
      }
      return "completed";
    }
    if (booking.extendedProps?.member?.value !== userId) {
      return "meetingRoom-other";
    }
    return "meetingRoom";
  }

  if (booking.extendedProps?.type?.value === "maintenance") {
    if (booking.dispatched) {
      if (booking.dispatchedBy?.id !== userId) {
        return "maintenance-dispatched-other";
      }
      return "maintenance-dispatched";
    }

    if (booking.completed) {
      if (booking.completedBy !== userId) {
        return "maintenance-completed-other";
      }
      return "maintenance-completed";
    }

    return "maintenance";
  }
  return booking.extendedProps?.type?.value;
};

const getBackgroundColor = (booking, userId, locationReservationTypes) => {
  console.log(
    "booking",
    booking?.completed,
    booking.extendedProps?.type?.value !== "maintenance"
  );
  if ((booking?.transferRequestInstructor ?? []).includes(userId))
    return "yellow";
  if (booking?.extendedProps?.type?.value === "unavailable") return "#a8a8a866";
  if (booking?.dispatched) {
    return "transparent";
  }
  if (
    booking?.completed &&
    booking.extendedProps?.type?.value !== "maintenance"
  )
    return "#9c9c9cff";
  else if (
    booking?.completed &&
    booking.extendedProps?.type?.value === "maintenance"
  )
    return (
      locationReservationTypes?.get(booking?.extendedProps?.type?.id)
        ?.colorCompleted || "#bb9c41"
    );
  if (!booking?.confirmed) return "#ffffff";
  if (
    booking?.extendedProps?.pilot?.value === userId ||
    booking?.extendedProps?.pilot2?.value === userId ||
    booking?.extendedProps?.instructor?.value === userId
  ) {
    return (
      locationReservationTypes?.get(booking?.extendedProps?.type?.id)?.color ||
      booking?.extendedProps?.type?.color
    );
  } else {
    return (
      locationReservationTypes?.get(booking?.extendedProps?.type?.id)
        ?.colorSecondary || booking?.extendedProps?.type?.colorSecondary
    );
  }
};

export { getBookingTitle, getClassName, getBackgroundColor };
