import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { InfoOutlined } from "@mui/icons-material";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";
import CourseInfoPopover from "../CourseInfoPopover";
import { Skeleton } from "@mui/material";
import CourseDocumentsPopover from "../CourseDocumentsPopover";
import { useClubs } from "src/features/club/ClubProvider";
import { getCourseDocumentsCollection } from "src/features/lms/collections";

function CourseHeader({ course, onBackClick, children }) {
  const { selectedClubId } = useClubs();
  const [anchorEl, setAnchorEl] = useState(null);

  const courseId = useMemo(() => course.course?.value || course.id, [course]);

  const handleInfoClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  return (
    <SoftBox
      mb={3}
      display="flex"
      alignItems="flex-start"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      <SoftBox display="flex" alignItems="center" flexGrow={0} flexShrink={3}>
        <IconButton onClick={onBackClick} aria-label="Back" sx={{ p: 0 }}>
          <ChevronLeftIcon fontSize="large" color="text" />
        </IconButton>
        <SoftTypography variant="h3" fontWeight="bold">
          {course?.title || <Skeleton />}
        </SoftTypography>
      </SoftBox>

      <SoftBox
        display="flex"
        flexDirection="row-reverse"
        justifyContent="flex-start"
        flexWrap="wrap"
        gap={2}
        flexGrow={2}
        alignItems="center"
      >
        <SoftBox
          display="flex"
          flexWrap="wrap"
          gap={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          <CourseDocumentsPopover
            courseId={courseId}
            documentsCollection={getCourseDocumentsCollection(
              selectedClubId,
              courseId
            )}
          />
          <SoftButton onClick={handleInfoClick} color="primary" size="small">
            <InfoOutlined />
            <SoftTypography
              fontSize="small"
              ml="4px"
              fontWeight="medium"
              color="white"
            >
              Course Information
            </SoftTypography>
          </SoftButton>
        </SoftBox>
        <SoftBox
          display="flex"
          gap={2}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="flex-end"
        >
          {children}
        </SoftBox>
        <CourseInfoPopover
          anchorEl={anchorEl}
          handleClosePopover={handleClosePopover}
          course={course}
        />
      </SoftBox>
    </SoftBox>
  );
}

CourseHeader.defaultProps = {
  onBackClick: () => {},
};

CourseHeader.propTypes = {
  course: PropTypes.object.isRequired,
  onBackClick: PropTypes.func,
  children: PropTypes.node,
};

export default CourseHeader;
