import { Card } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { getLessonTasksCollection } from "src/features/lms/collections";
import { Checkbox } from "@mui/material"
import { useState } from "react";

const schema = yup
  .object({
    title: yup.string().required("A lesson title is required."),
  })
  .required();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "15px",
  overflow: "scroll",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
};

export function AddTaskModal({
  handleClose,
  courseId,
  stageId,
  lessonId,
  taskCount,
  taskData,
}) {
  const { selectedClubId } = useClubs();
  const { addData, updateData } = entityCrudUtils();
  const [isOptional, setIsOptional] = useState(taskData?.isOptional || false)
  const handleSetIsOptional = () => setIsOptional(!isOptional);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: taskData?.title,
      isOptional: false,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    if (taskData?.id) {
      await updateData(
        {
          entity: getLessonTasksCollection(
            selectedClubId,
            courseId,
            stageId,
            lessonId
          ),
          pathSegmentsArr: [taskData.id],
        },
        {
          ...taskData,
          ...data,
        },
        taskData.id
      );
      handleClose({
        ...taskData,
        ...data,
      });
    } else {
      const addedTaskRef = await addData(
        getLessonTasksCollection(selectedClubId, courseId, stageId, lessonId),
        {
          ...data,
          lessonId,
          stageId,
          courseId,
          index: taskCount,
        }
      );
      handleClose({
        id: addedTaskRef.id,
        ...data,
        lessonId,
        stageId,
        courseId,
        index: taskCount,
      });
    }
  };

  return (
    <Card sx={style}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            {taskData?.id ? "Edit" : "Add New"} Task
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={3}>
              <Grid item xs={12} mt={2}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Title
                  </SoftTypography>
                </SoftBox>
                <SoftBox pl={2} pt={2}>
                  <SoftInput
                    {...register("title", {
                      required: true,
                    })}
                    placeholder="Eg. Checklists Proceedures"
                  />
                  {errors?.title?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.title.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12} mt={2}>
                <SoftBox
                  ml={0.5}
                  lineHeight={0}
                  display="flex"
                  alignItems="center"
                  gap={1.5}
                >
                  <SoftTypography
                    component="label"
                    fontSize="small"
                    fontWeight="medium"
                  >
                    Task is optional:
                  </SoftTypography>
                  <Checkbox
                    checked={isOptional}
                    onClick={handleSetIsOptional}
                    {...register("isOptional", {
                      required: false,
                    })}
                  />
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={3}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    {taskData?.id ? "Update Task" : "Add Task"}
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

AddTaskModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  courseId: PropTypes.string.isRequired,
  stageId: PropTypes.string.isRequired,
  lessonId: PropTypes.string.isRequired,
  taskCount: PropTypes.number.isRequired,
  taskData: PropTypes.object,
};
