import SoftBox from "src/components/SoftBox";
import PropTypes from "prop-types";
import { useState, useEffect, useMemo } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { useClubs } from "src/features/club/ClubProvider";

import Icon from "@mui/material/Icon";
import SoftButton from "src/components/SoftButton";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import colors from "src/assets/theme/base/colors";
import { AddLessonModal } from "src/modals/AddLessonModal";
import { iconButtonStyles } from "src/components/Tables/DragAndDrop/iconButtonStyles";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  HeaderCell,
  HeaderCellText,
} from "src/components/Tables/DragAndDrop/HeaderCell";
import DraggableRow from "src/components/Tables/DragAndDrop/DraggableRow";
import DroppableBody from "src/components/Tables/DragAndDrop/DroppableBody";

import { DragDropContext } from "react-beautiful-dnd";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { getCourseLessonsCollection } from "src/features/lms/collections";
import Swal from "sweetalert2";
import SoftTypography from "src/components/SoftTypography";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { usePermissions } from "src/hooks/usePermissions";
import { Checkbox, CircularProgress } from "@mui/material";

// const { borderRadius } = borders;
const { grey } = colors;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function LessonTableContent({ lessons, stageId, refreshData }) {
  const { selectedClubId } = useClubs();
  const { hasAccess } = usePermissions();
  const { courseId } = useParams();
  const { deleteData } = entityCrudUtils();
  const [selectedLesson, setSelectedLesson] = useState(null);

  const [_deletedLesson, setDeletedLesson] = useState(null);
  const [openAddLessonModal, setOpenAddLessonModal] = useState(false);

  const showAddLessonModal = () => setOpenAddLessonModal(true);
  const closeAddLessonModal = () => {
    setSelectedLesson(null);
    setOpenAddLessonModal(false);
    refreshData();
  };

  const handleEditLesson = (lesson) => {
    setSelectedLesson(lesson);
    showAddLessonModal();
  };

  const handleDeleteLesson = (lessonId) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You will not be able to recover this lesson!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          setDeletedLesson(
            lessons?.splice(
              lessons.findIndex((val) => val.id === lessonId),
              1
            )
          );
          deleteData({
            entity: getCourseLessonsCollection(
              selectedClubId,
              courseId,
              stageId
            ),
            pathSegmentsArr: [lessonId],
          });
        }
      });
  };

  return (
    <>
      {lessons.length ? (
        <Table
          stickyHeader
          aria-label="collapsible table"
          style={{ borderSpacing: "0 0.2rem" }}
        >
          <TableHead>
            <TableRow
              sx={{
                "& > *": { backgroundColor: "white!important" },
              }}
            >
              <HeaderCell>
                <HeaderCellText>#</HeaderCellText>
              </HeaderCell>
              <HeaderCell>
                <HeaderCellText>Lesson</HeaderCellText>
              </HeaderCell>
              <HeaderCell>
                <HeaderCellText>Optional</HeaderCellText>
              </HeaderCell>
              <HeaderCell>
                <HeaderCellText>Flight Time (h)</HeaderCellText>
              </HeaderCell>
              <HeaderCell>
                <HeaderCellText>Ground Time (h)</HeaderCellText>
              </HeaderCell>
              <HeaderCell>
                <HeaderCellText>Sim Time (h)</HeaderCellText>
              </HeaderCell>
              <HeaderCell>
                <HeaderCellText>Tasks</HeaderCellText>
              </HeaderCell>
              <WithPermissions permissions={systemPermissions.EDIT_LMS_COURSES}>
                <HeaderCell alignRight>
                  <HeaderCellText alignRight>Actions</HeaderCellText>
                </HeaderCell>
              </WithPermissions>
            </TableRow>
          </TableHead>
          <TableBody component={DroppableBody(stageId)}>
            {lessons?.map((lesson) => (
              <Row
                lesson={lesson}
                key={lesson.index}
                handleDeleteLesson={handleDeleteLesson}
                handleEditLesson={handleEditLesson}
                refreshData={refreshData}
              />
            )) ?? (
              <TableRow>
                <TableCell sx={{ color: grey[500] }}>
                  <i>No Lessons Added</i>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      ) : (
        <SoftBox sx={{ py: 2 }}>
          {hasAccess(systemPermissions.EDIT_LMS_COURSES) ? (
            <>
              <SoftTypography variant="h4" sx={{ mb: 1 }} color="primary">
                This stage needs a little... <i>*pizzaz*</i>
              </SoftTypography>
              <SoftTypography fontSize="medium" fontWeight="medium">
                Add your first lesson below!
              </SoftTypography>
            </>
          ) : (
            <SoftTypography variant="h4" sx={{ mb: 1 }} color="primary">
              There are no lessons in this stage
            </SoftTypography>
          )}
        </SoftBox>
      )}
      <WithPermissions permissions={systemPermissions.EDIT_LMS_COURSES}>
        <SoftButton
          onClick={showAddLessonModal}
          variant="outlined"
          color="primary"
          sx={{ my: 2 }}
        >
          <span style={{ paddingRight: "0.4rem" }}>+</span>
          Add Lesson
        </SoftButton>
      </WithPermissions>
      <Modal
        open={openAddLessonModal}
        onClose={() => closeAddLessonModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddLessonModal
            courseId={courseId}
            stageId={stageId}
            lessonCount={lessons?.length}
            lessonData={selectedLesson}
            handleClose={closeAddLessonModal}
          />
        </SoftBox>
      </Modal>
    </>
  );
}
LessonTableContent.defaultProps = {
  refreshData: () => {},
};
LessonTableContent.propTypes = {
  lessons: PropTypes.array.isRequired,
  stageId: PropTypes.string.isRequired,
  refreshData: PropTypes.func,
};

function Row({ lesson, handleEditLesson, handleDeleteLesson, refreshData }) {
  const navigate = useNavigate();
  const { selectedClubId } = useClubs();
  const { updateData } = entityCrudUtils();
  const [isLoading, setIsLoading] = useState(false)
  const isOptional = useMemo(() => (
    lesson.isOptional || false
  ), [lesson])

  const updateLessonIsOptional = async (newValue) => {
    await updateData(
      {
        entity: getCourseLessonsCollection(selectedClubId, lesson.courseId, lesson.stageId),
        pathSegmentsArr: [lesson.id],
      },
      {
        ...lesson,
        isOptional: newValue,
      },
      lesson.id
    );
    refreshData(true)
  }

  const handleToggleCheck = () => {
    setIsLoading(true)
    updateLessonIsOptional(!isOptional)
  }

  useEffect(() => {
    setIsLoading(false)
  }, [lesson])

  return (
    <>
      <TableRow
        component={DraggableRow(lesson.id, lesson.index)}
        onClick={() =>
          navigate(
            `/lms/courses/${lesson.courseId}/${lesson.stageId}/${lesson.id}`
          )
        }
      >
        <TableCell>{lesson.index + 1}</TableCell>
        <TableCell
          style={{
            minWidth: "150px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {lesson.title}
        </TableCell>
        <TableCell>
          {isLoading ? (
            <CircularProgress size="1.5rem" sx={{ animationDuration: "400ms"}} />
          ) : (
            <Checkbox
              aria-label={`${lesson.title} optional`}
              checked={lesson.isOptional || false}
              onClick={(e) => e.stopPropagation()}
              onChange={handleToggleCheck}
            />
          )}
        </TableCell>
        <TableCell>{lesson.flightTime}</TableCell>
        <TableCell>{lesson.groundTime}</TableCell>
        <TableCell>{lesson.simTime}</TableCell>
        <TableCell>{lesson.tasks?.length || 0}</TableCell>
        <WithPermissions permissions={systemPermissions.EDIT_LMS_COURSES}>
          <TableCell align="right">
            <SoftBox sx={{ display: "flex", flexDirection: "row-reverse" }}>
              <IconButton aria-label="expand row" sx={iconButtonStyles}>
                <KeyboardArrowRightIcon />
              </IconButton>
              <IconButton
                aria-label="edit"
                sx={iconButtonStyles}
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditLesson(lesson);
                }}
              >
                <Icon>edit</Icon>
              </IconButton>
              <IconButton
                aria-label="delete"
                sx={iconButtonStyles}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteLesson(lesson.id);
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            </SoftBox>
          </TableCell>
        </WithPermissions>
      </TableRow>
    </>
  );
}

Row.defaultProps = {
  handleEditLesson: () => {},
  handleDeleteLesson: () => {},
};
Row.propTypes = {
  lesson: PropTypes.object.isRequired,
  handleEditLesson: PropTypes.func,
  handleDeleteLesson: PropTypes.func,
  refreshData: PropTypes.func
};

const LessonTable = ({ stage, refreshData }) => {
  const { selectedClubId } = useClubs();
  const { courseId } = useParams();
  const [lessons, setLessons] = useState([]);

  const onRowMove = async (newLessons, sourceIndex, destinationIndex) => {
    const { updateData } = entityCrudUtils();

    if (sourceIndex === destinationIndex) {
      return;
    } else {
      newLessons.forEach(async (lesson) => {
        // update lesson using updateData from entityCrudUtils
        await updateData(
          {
            entity: getCourseLessonsCollection(
              selectedClubId,
              courseId,
              stage?.id
            ),
            pathSegmentsArr: [lesson.id],
          },
          { index: lesson.index }
        );
      });
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination || result.destination === result.source) {
      return;
    }

    let newLessons = [...lessons];

    newLessons = reorder(
      newLessons,
      result.source.index,
      result.destination.index
    )
      .map((lesson, index) => ({ ...lesson, index }))
      .sort((a, b) => a.index - b.index);

    setLessons(newLessons);
    onRowMove(newLessons, result.source.index, result.destination.index);
  };

  useEffect(() => {
    setLessons(stage.lessons);
  }, [stage]);

  return (
    <TableContainer
      sx={{
        maxHeight: "100%",
        boxShadow: "none",
        borderRadius: "0",
        overflowX: "visible",
        marginTop: 3,
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <LessonTableContent
          lessons={lessons}
          stageId={stage.id}
          refreshData={refreshData}
        />
      </DragDropContext>
    </TableContainer>
  );
};

LessonTable.defaultProps = {
  refreshData: () => {},
};

LessonTable.propTypes = {
  stage: PropTypes.object.isRequired,
  refreshData: PropTypes.func,
};

export default LessonTable;
