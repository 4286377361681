import { useEffect, useState, useMemo } from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import Calendar from "src/components/Calendar/Calendar";
import Footer from "src/components/Footer";
import DashboardNavbar from "src/containers/DashboardNavbar";
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import { useUser } from "src/features/user/UserProvider";
import { useCollection } from "react-firebase-hooks/firestore";
import { query, where } from "firebase/firestore";
import { getClubBookingsCollection } from "src/features/club/collections";
import {
  getBackgroundColor,
  getClassName,
} from "src/pages/book-flight/Calendar/uiHelper";
import { useClubs } from "src/features/club/ClubProvider";

import {
  fetchAircraftResourceData,
  fetchClubPilotsData,
  fetchInstructorResourceData,
  fetchMeetingRoomResourceData,
} from "src/pages/book-flight/utils";

import SoftSelect from "src/components/SoftSelect";
import { Modal } from "@mui/material";
import { BookingModal } from "src/modals/BookingModal";
import BookingInfoModal from "src/modals/BookingInfoModal";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import WithPermissionWrapper from "src/components/WithPermissions/WithPermissionWrapper";
import useClubMeetingRooms from "src/features/meeting-room/meetingRoomsHelper";

const allSelectValue = { label: "All", value: "all" };

function InstructorsSchedule() {
  const { userId } = useUser();
  const {
    selectedClubId,
    selectedLocationId,
    selectedClub,
    locationUsers,
    clubUsersPermissions,
    instructors: clubInstructors,
    instructorPreferences: clubInstructorPrefs,
    locationReservationTypes,
  } = useClubs();
  const { meetingRooms } = useClubMeetingRooms();
  const [instructors, setInstructors] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [selectedInstructor, setSelectedInstructor] = useState(allSelectValue);
  const [instructorSelectValues, setInstructorSelectValues] = useState([
    allSelectValue,
  ]);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [openBookingInfo, setOpenBookingInfo] = useState(false);
  const [resourceData, setResourceData] = useState([]);
  const [pilotData, setPilotData] = useState([]);
  const [selection, setSelection] = useState();

  const instructorsData = useMemo(() => {
    return fetchInstructorResourceData(clubInstructors, clubInstructorPrefs);
  }, [clubInstructors, clubInstructorPrefs]);

  const fetchData = async () => {
    const aircraftData = await fetchAircraftResourceData(
      selectedClubId,
      selectedLocationId,
      selectedClub
    );
    const pilotsData = fetchClubPilotsData(locationUsers, clubUsersPermissions);
    const meetingRoomData = fetchMeetingRoomResourceData(meetingRooms);

    setResourceData([...instructorsData, ...aircraftData, ...meetingRoomData]);
    setPilotData(pilotsData);
    setInstructors(instructorsData);
  };

  useEffect(() => {
    fetchData();
  }, [selectedClubId, selectedLocationId, instructorsData]);

  const [bookingsSnapshot, loadingBookings] = useCollection(
    query(
      getClubBookingsCollection(selectedClubId, selectedLocationId),
      where("cancelled", "==", false)
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  useEffect(() => {
    if (instructors?.count === 0) return;

    const instructorSelect = [allSelectValue];

    instructors.map((instructor) => {
      instructorSelect.push({
        label: instructor.title,
        value: instructor.id,
      });
    });

    setInstructorSelectValues(instructorSelect);
  }, [instructors]);

  useEffect(() => {
    if (loadingBookings) return;

    const addLoadedBookings = (bookings, loadedBookings) => {
      const selectedInstructorId = selectedInstructor.value;

      loadedBookings.map((document) => {
        const booking = document.data();

        if (
          booking.extendedProps.instructor &&
          booking.extendedProps.instructor.value
        ) {
          if (
            selectedInstructorId !== "all" &&
            booking.extendedProps.instructor.value !== selectedInstructorId
          )
            return;

          bookings.push({
            ...booking,
            title: booking.extendedProps.instructor.label,
            id: document.id,
            start: booking.start.toDate(),
            end: booking.end.toDate(),
            editable: false,
            backgroundColor: getBackgroundColor(
              booking,
              userId,
              locationReservationTypes
            ),
            className: getClassName(booking, userId),
          });
        }
      });
    };

    const bookings = [];
    addLoadedBookings(bookings, bookingsSnapshot.docs);

    setBookingData(bookings);
  }, [bookingsSnapshot, selectedInstructor]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelection(null);
    setOpen(false);
  };

  const handleSelection = (data) => {
    if (selectedInstructor && selectedInstructor.value !== "all") {
      const resource = resourceData.find(
        (instructor) => instructor.id === selectedInstructor.value
      );
      setSelection({
        ...data,
        resource: {
          id: resource.id,
          title: resource.title,
          extendedProps: {
            ...resource,
          },
        },
      });
    } else {
      setSelection(data);
    }

    handleOpen();
  };

  const handleInstructorChange = (event) => {
    setSelectedInstructor(event);
  };

  const handleBookingInfoOpen = (booking) => {
    setSelectedBookingId(booking.id);
    setOpenBookingInfo(true);
  };

  const handleBookingInfoClose = () => {
    setOpenBookingInfo(false);
    setSelectedBookingId(null);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox p={3}>
        <SoftTypography variant="h5">Instructors</SoftTypography>
      </SoftBox>

      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <SoftSelect
              options={instructorSelectValues}
              value={selectedInstructor}
              onChange={handleInstructorChange}
            />
          </Grid>
        </Grid>
      </SoftBox>

      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SoftBox mt={3}>
              <Card>
                <Calendar
                  events={bookingData}
                  eventClick={(info) => handleBookingInfoOpen(info.event)}
                  displayEventEnd
                  selectable
                  select={handleSelection}
                  initialView="timeGridWeek"
                  /*    events={bookingData}
                    pilots={pilotData}
                    resourceLabelContent={renderResourceContent}
                    resources={fetchResourceData}
                    resourceData={resourceData}
                    // eventDidMount={eventDidMount}
                    eventClick={(info) => handleBookingInfoOpen(info.event)} */
                />
              </Card>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <BookingModal
            selection={selection}
            handleClose={handleClose}
            resources={resourceData}
            pilots={pilotData}
          />
        </SoftBox>
      </Modal>

      <Modal
        open={openBookingInfo}
        onClose={handleBookingInfoClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <BookingInfoModal
            bookingId={selectedBookingId}
            handleClose={handleBookingInfoClose}
            pilots={pilotData}
            resources={resourceData}
          />
        </SoftBox>
      </Modal>

      <Footer />
    </DashboardLayout>
  );
}

export default WithPermissionWrapper(
  systemPermissions.VIEW_INSTRUCTORS_SCHEDULE,
  InstructorsSchedule
);
